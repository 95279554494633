<template>
  <div class="homecarelist">
    <el-row :gutter="20">
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">总计下单量</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.totalOrder }}</h2>
          </el-card>
        </div>
      </el-col>
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">未提交单量</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.unSubmitNum }}</h2>
          </el-card>
        </div>
      </el-col>
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">待审核订单</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.reviewOrder }}</h2>
          </el-card>
        </div>
      </el-col>
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">待付款订单</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.waitPayOrder }}</h2>
          </el-card>
        </div>
      </el-col>
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">未电子委托</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.authorizeOrder }}</h2>
          </el-card>
        </div>
      </el-col>
      <el-col :sm="8" :xs="8" :md="8" :lg="4">
        <div class="j-card">
          <el-card>
            <h5 class="fs-6">本月下单量</h5>
            <h2 class="fs-2 text-secondary">{{ homeData.orderOfMonth }}</h2>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { inject, onBeforeMount } from 'vue';
import HomeStatisticsClass from './class/homeStatisticsClass';

export default {
  name: 'homecarelist',
  setup() {
    const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆信息数据集合
    const storeDatas = inject('storeDatas');
    const homeData = inject('homeData'); // 本页相关数据

    const homeStatisticsClass = new HomeStatisticsClass(homeData, setFilterOfContainer, storeDatas); // 当前页的类
    onBeforeMount(() => {
      // 判断是否需要加载数据
      if (setFilterOfContainer.loadHomeStatistics) {
        // 【fase：无需加载，true：需要重新加载】
        // 加载数据，1，向服务器查询统计数据
        homeStatisticsClass.myAxiosOfStatisTics();
      }
    });

    return {
      homeData,
      setFilterOfContainer,
      storeDatas,
      homeStatisticsClass,
    };
  },
};
</script>
<style lang="scss" scoped>
.j-card {
  height: 130px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  width: 100% !important;
  height: 100% !important;

  :deep(.el-card) {

    .el-card__body {
      height: 100%;

      h5,
      h2 {
        line-height: 40px;
        font-weight: 800;
        font-family: '微软雅黑';
        text-align: center;
      }
    }
  }
}
</style>
