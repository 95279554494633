<template>
  <div class="footerdiv">
    <div class="wechart"><img src="@/assets/imgs/home/wechart.png" alt=""></div>
    <div>Copyright © 2021 syncwk.com All Right Reserved</div>
    <div>
      网站备案 | 许可证号：
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备13057959号</a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footerdiv {
	// font-family: '黑体';
	color: $hovefontcolor;
	margin-top: 50px;
  .wechart{
    margin-bottom: .8rem;
    img{
    width: 8rem;
    }

  }
	a {
		color: $buttomcolor;
	}
}
</style>
