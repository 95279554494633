<template>
	<div class="box-card">
		<!-- <el-card class="box-card"> -->
		<div class="leftBox">
			<img src="@/assets/imgs/home/sysapply01.jpg" draggable="false" alt="海运出口单证" />
			<!-- <el-image :src="require('@/assets/imgs/home/sysapply01.jpg')" fit="fill"></el-image> -->
			<div class="word_content">
				<div class="btn">
					<img src="@/assets/imgs/home/button.png" draggable="false" alt="海运出口单证" />
					<img src="@/assets/imgs/home/oceanShip.svg" draggable="false" alt="出口单证" />
				</div>
				<div class="word">
					<span>海运</span>
					<br />出口
				</div>

				<div class="enWord">Ocean</div>
			</div>
		</div>
		<div class="centerBox">
			<div class="centerTop">
				<img src="@/assets/imgs/home/sysapply02.jpg" draggable="false" alt="空运出口单证" />
				<!-- <el-image :src="require('@/assets/imgs/home/sysapply02.jpg')" fit="scale-down"></el-image> -->
				<div class="center_world_content">
					<div class="btn">
						<img src="@/assets/imgs/home/button.png" draggable="false" alt="空运出口出口单证" />
						<img src="@/assets/imgs/home/aircargo.svg" draggable="false" alt="空运出口出口单证" />
					</div>
					<div class="word">
						<span>空运</span>
						<br />AirCargo
					</div>
				</div>
			</div>
			<div class="centerBottom">
				<img src="@/assets/imgs/home/sysapply04.jpg" draggable="false" alt="散货出口单证" />
				<!-- <el-image :src="require('@/assets/imgs/home/sysapply02.jpg')" fit="scale-down"></el-image> -->
				<div class="center_world_content">
					<div class="btn">
						<img src="@/assets/imgs/home/button.png" draggable="false" alt="散货出口单证" />
						<img src="@/assets/imgs/home/lcl.svg" draggable="false" alt="散货出口单证" />
					</div>
					<div class="word">
						<span>散货</span>
						<br />LCL Cargo
					</div>
				</div>
			</div>
		</div>
		<div class="rightBox">
			<img src="@/assets/imgs/home/sysapply03.webp" draggable="false" alt="快递出口单证" />
			<!-- <el-image :src="require('@/assets/imgs/home/sysapply01.jpg')" fit="fill"></el-image> -->
			<div class="word_content">
				<div class="btn">
					<img src="@/assets/imgs/home/button.png" draggable="false" alt="出口单证" />
					<img src="@/assets/imgs/home/express.svg" draggable="false" alt="快递出口报关资料单证" />
				</div>
				<div class="word">
					<span>快递</span>
					<br />出口
				</div>
				<div class="enWord">Express</div>
			</div>
		</div>
	</div>
	<!-- </el-card> -->
</template>
<script>
import { reactive, toRefs } from 'vue';

export default {
	setup() {
		/**
		 * 数据
		 */
		const datas = reactive({

		});
		/**
		 * 方法
		 */
		const myMethods = reactive({
		});
		return {
			...toRefs(datas),
			...toRefs(myMethods),
		};
	},
};
</script>
<style lang="scss" scoped>
/**
注释：
1，绘制一个容器；
2，根据窗体大小的改变而改变min-height的值；
3，容器内按百分比分为三等分，为了窗体改变而动态变化每个image大小；
4，绝对定位每个图片父元素div的位置；
5，图片高宽以100%显示；
**/
$spacedata: 0.8rem; //边距padding
$bottomDivbgColor: $light; //底部样式背景色
$fontSize: 1.2rem;

// $borderColor: $light; //边框颜色
// 每个div的共有样式
@mixin div_shar_css {
	overflow: hidden;
	box-sizing: border-box;
	// border: 1px solid $borderColor;
	border-radius: 10px;
	box-shadow: 0px 1px 3px $hovefontcolor;
}

.box-card {
	width: 100%;
	min-height: 400px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	background-color: $white;
	box-shadow: 1px 2px 2px $light;
	position: relative;
	border-radius: 5px;

	.leftBox,
	.rightBox {
		width: calc(100% * 0.279 - #{$spacedata}) !important;
		height: calc(100% - #{$spacedata} * 2) !important;
		position: absolute;
		@include div_shar_css;

		>img {
			width: 100%;
			height: 100%;
		}

		.word_content {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 30%;
			width: 100%;
			background-color: rgba($bottomDivbgColor, 0.4);
			border: 1px solid rgba($bottomDivbgColor, 0.3);
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: $white;
			font-weight: bold;

			.btn {
				display: flex;
				align-items: center;
			}

			.word {
				font-size: $fontSize;
				width: 26%;
				line-height: 1.8rem;

				>span {
					color: $activefontcolor;
				}
			}
		}

		transition: transform 0.3s ease-in-out;

		&:hover {
			transform: scale(1.04);
		}
	}

	.leftBox {
		left: $spacedata;
		top: $spacedata;

		.enWord {
			font-size: 3rem;
			position: absolute;
			transform: rotate(90deg);
			top: -120%;
			left: 40%;
			width: 100%;
			height: 50%;
		}
	}

	.rightBox {
		right: $spacedata;
		top: $spacedata;

		.word_content {
			background-color: rgba($bottomDivbgColor, 0.3);
		}

		.enWord {
			font-size: 3rem;
			position: absolute;
			top: -80%;
			left: 5%;
			width: 100%;
			height: 50%;
		}
	}

	.centerBox {
		position: absolute;
		width: 100% * 0.442;
		height: calc(100% - #{$spacedata} * 2);
		top: $spacedata;
		left: 50% - 100% * 0.442/2;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 0 $spacedata;

		>div {
			position: relative;
			height: 50%;
			@include div_shar_css;
			transition: transform 0.3s ease-in-out;

			&:hover {
				transform: scale(1.02);
			}

			>img {
				width: 100%;
				height: 100%;
			}

			.center_world_content {
				display: flex;
				position: absolute;
				color: $white;
				font-weight: bold;

				.btn {
					display: flex;
					align-items: center;
					// justify-content: flex-end;
				}

				.word {
					font-size: $fontSize;
					width: 50%;
					line-height: 1.5rem;

					>span {
						color: $activefontcolor;
					}
				}
			}
		}

		.centerTop {
			margin-bottom: $spacedata/2;

			.center_world_content {
				left: 0;
				top: 0;
				height: 100%;
				width: 80%;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.centerBottom {
			margin-top: $spacedata/2;

			.center_world_content {
				flex-direction: column;
				height: 100%;
				width: 35%;
				right: 0 !important;
				bottom: 0 !important;
				justify-content: flex-end;
				align-items: flex-end;

				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;

					img {
						// margin-bottom: 0.5rem;
						// width: 45%;
					}
				}

				.word {
					font-size: $fontSize;
					width: 100%;
					margin-bottom: 1rem;
					line-height: 1.8rem;
					text-align: center;

					>span {
						color: $activefontcolor;
					}
				}
			}
		}
	}

	//按钮图片
	.btn {
		position: relative;
		justify-content: center;

		img {
			&:last-child {
				width: 55%;
				height: 55%;
				position: absolute;
				display: flex;
				justify-content: center;
			}
		}
	}
}

@media (max-width: 1050px) {
	.box-card {
		min-height: 300px !important;

		.word {
			font-size: 1rem !important;
			line-height: 1.2rem !important;
		}
	}
}

@media (min-width: 1300px) {
	.box-card {
		min-height: 500px !important;

		.word {
			font-size: 1.4rem !important;
			line-height: 1.8rem !important;
		}
	}
}

@media (min-width: 1500px) {
	.box-card {
		min-height: 600px !important;

		.word {
			font-size: 1.6rem !important;
			line-height: 2rem !important;
		}
	}
}
</style>
