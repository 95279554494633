<template>
  <div class="slotContent">
    <div class="slotBox" v-for="(item,index) in childDatas" :key="index" :style="'z-index:'+index">
      <help-content-slot :elColors="item.elColors">
        <template #topDiv>
          <div v-if="index%2===0" class="topDiv">
            <!-- <div class="topDiv"> -->
            <div class="topDivLine" :style="'background:'+item.elColors[3]">
              <img v-bind:src="require('@/assets/imgs/home/'+item.icon+'.svg')" class="imgs" />
            </div>
            <div class="topSpan">{{item.descName}}</div>
          </div>
          <div v-else class="bottomDiv">
            <div class="topDivLine" :style="'background:'+item.elColors[3]">
              <img v-bind:src="require('@/assets/imgs/home/'+item.icon+'.svg')" class="imgs" />
            </div>
            <div class="topSpan">{{item.descName}}</div>
          </div>
        </template>
      </help-content-slot>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import HelpContentSlot from './HelpContenSlot.vue';

export default {
  components: { HelpContentSlot },
  setup() {
    const datas = reactive({
      childDatas: [
        { icon: 'order', descName: '创建订单', elColors: ['#EB3600', '#D92600', '#A70012', '#9E0015'] },
        { icon: 'submit', descName: '提交数据', elColors: ['#008390', '#004B60', '#005A70', '#0095A1'] },
        { icon: 'shenhe', descName: '审核数据', elColors: ['#0F8EC7', '#169BCE', '#045D9B', '#0367AF'] },
        { icon: 'custmosdoc', descName: '生成报关文件', elColors: ['#A50072', '#900074', '#65007C', '#600185'] },
        { icon: 'pay', descName: '扫码支付', elColors: ['#A35602', '#990013', '#900710', '#A96A00'] },
        { icon: 'weituo', descName: '电子报关委托授权', elColors: ['#F03800', '#F03800', '#723800', '#643800'] },
        { icon: 'okay', descName: '打印文件', elColors: ['#0085e8', '#0083e8', '#0085e8', '#2f4050'] },
        // { icon: 'okay', descName: '完成交易', elColors: ['#FDF300', '#FDA200', '#FD9100', '#F81A00'] },
      ],
    });
    return {
      ...toRefs(datas),
    };
  },
};
</script>
<style lang="scss" scoped>
.slotContent {
	display: flex;
	justify-content: center;
	// min-width:560px !important;

	.slotBox {
		width: calc(100% / 8);
		height: 280px;
		transition: transform 0.3s ease-in-out;
		&:hover {
			transform: scale(1.1);
		}
	}
}
.imgs {
	width: 80%;
	height: 80%;
}

.topDiv {
	position: absolute;
	top: 0;
	right: 0;
	width: $divBaseSpace * 4;
	height: 50%;
	display: flex;
	justify-content: center;
	z-index: 1;
	// background-color: red;
	.topDivLine {
		width: $divBaseSpace * 3;
		height: $divBaseSpace * 3;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 20%;
		border-radius: 20%;
	}
	&::before {
		content: '';
		position: absolute;
		left: 24px;
		top: 30%;
		width: 2px;
		height: 80%;
		background-color: $hovefontcolor;
		z-index: 0;
	}
	.topSpan {
		text-align: center;
		font-size: 12px;
		line-height: 15px;
		text-shadow: 0 0 3px $hovefontcolor;
	}
}

.bottomDiv {
	position: absolute;
	top: 50%;
	right: 0;
	width: $divBaseSpace * 4;
	height: 50%;
	display: flex;
	justify-content: center;
	z-index: 1;
	.topDivLine {
		width: $divBaseSpace * 3;
		height: $divBaseSpace * 3;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		border-radius: 20%;
	}
	&::before {
		content: '';
		position: absolute;
		left: 24px;
		top: 20px;
		width: 2px;
		height: 50%;
		background-color: $hovefontcolor;
		z-index: 0;
	}
	.topSpan {
		text-align: center;
		font-size: 12px;
		line-height: 15px;
		text-shadow: 0 0 3px $hovefontcolor;
		position: absolute;
		top: 90%;
	}
}
</style>
