import { ElMessage } from 'element-plus';
import MyAxios from '../../../utils/axios/index_v2';

export default class HomeStatisticsClass {
  constructor(homeData, setFilterOfContainer, storeDatas) {
    this.homeData = homeData;// 当前页所需数据
    this.setFilterOfContainer = setFilterOfContainer;// 登陆信息
    this.storeDatas = storeDatas;// 路由相关数据
    this.initFunc();
  }

  // 初始化将函数加载setFilterOfContainer中
  initFunc = () => {
    this.setFilterOfContainer.loadHomeFunc = this.myAxiosOfStatisTics;
    this.setFilterOfContainer.clearHomeFunc = this.clearHomeData;
  }

  // 重置homeData中数据
  clearHomeData = () => {
    Object.keys(this.homeData).forEach((item) => {
      this.homeData[item] = 0;
    });
  }

  // 向后台服务器加载统计数据
  myAxiosOfStatisTics = () => {
    MyAxios().initAxios({
      method: 'GET',
      url: '/home_statistics',
    }).then((res) => {
      if (res.code === 200 && res.message === '成功') {
        // 更新数据
        Object.assign(this.homeData, res.result);
      }
    }).catch((err) => {
      ElMessage.log(err);
    });
    this.setFilterOfContainer.loadHomeStatistics = false;// 重新标记为已加载过【fase：无需加载，true：需要重新加载】
  }
}
