<template>
	<div class="quote_box">
		<div class="title">
			<slot name="title">阶梯名称</slot>
		</div>
		<div class="priceText" :style="'background-color:' + bgColor">
			<div>
				<div class="priceTestTilte">
					<span class="unit">￥</span>
					<span class="price">
						<slot name="price">元</slot>
					</span>
					<span class="hanfen">/</span>
					<span class="perMonth">单</span>
				</div>
				<div class="priceTextDesc">
					<span>
						<slot name="priceTextDesc">单量每月10票以内</slot>
					</span>
				</div>
			</div>
		</div>
		<div class="desc">
			<slot name="desc">
				<ul>
					<li>每月单量小于10票</li>
					<li>包含第10票</li>
					<li>超出部分自动以第二阶梯计价</li>
					<li>价格系统自动生成</li>
				</ul>
			</slot>
		</div>
		<div class="startbtn bg-white pt-5 pb-3 justify-content-center d-flex">
			<el-button type="primary" class="w-75" @click="actionStar">开始制单</el-button>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';

export default {
	name: 'quote-service-slot',
	props: ['bgcolor'],
	setup(props) {
		const router = useRouter();
		/**
		 * 数据
		 */
		const datas = reactive({
			bgColor: props.bgcolor,
		});
		/**
		 * 方法
		 */
		const myMethods = reactive({
			// 点击制作
			actionStar: () => {
				router.replace('createcustoms');
			},
		});
		return {
			...toRefs(datas),
			...toRefs(myMethods),
		};
	},
};
</script>
<style lang="scss" scoped>
$myBase: 100%/3;

.quote_box {
	margin-right: $base * 0.5;
	width: $myBase;
	height: 100%;
	border-radius: $base/3;

	border: 0.1em solid $light;

	&.quote_box:last-child {
		margin-right: 0;
	}

	.title {
		height: $base * 3;
		background: $submenucolor;
		text-align: center;
		color: $white;
		line-height: $base * 3;
		font-size: $base;
		font-weight: bold;
		border-top-left-radius: $base/4;
		border-top-right-radius: $base/4;
	}

	.priceText {
		// background-color: $hovefontcolor;
		// background-color: $cardbg3;
		height: $base * 12;
		color: $white;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		>div {
			text-align: center;
			font-weight: 600;
			text-shadow: 0 1px 1px $dark;
			font-family: '微软雅黑';
			font-size: $base;

			.priceTestTilte {
				.unit {
					font-size: $base * 1.5;
				}

				.price {
					font-size: $base * 3;
					font-weight: bold;
					font-family: 'Helvetica';
					text-shadow: 0 2px 1px $dark;
				}

				.hanfen {
					font-size: $base * 2.5;
				}
			}
		}
	}

	.desc {
		ul {
			background-color: white;

			li {
				// padding-left: $base * 0.5;
				border-bottom: 1px solid $light;
				line-height: $base * 2.8;
				font-size: $base * 0.8;
				text-align: center;
			}
		}
	}

	.startbtn {
		border-bottom-right-radius: $base/4;
		border-bottom-left-radius: $base/4;
	}
}
</style>
