<template>
  <div class="price_box">
    <quote-service-slot :bgcolor="bgColor[0]">
      <template #title>第一阶梯</template>
      <template #priceTextDesc>近30天单量10单内</template>
      <template #price>50</template>
      <template #desc>
        <ul class="desc">
          <li>近30天单量10票内</li>
          <li>单量小于10票以内收费</li>
          <li>超出部分自动以第二阶梯计价</li>
          <li>价格系统自动生成</li>
        </ul>
      </template>
    </quote-service-slot>
    <quote-service-slot :bgcolor="bgColor[1]">
      <template #title>第二阶梯</template>
      <template #priceTextDesc>近30天单量超出10单</template>
      <template #price>30</template>
      <template #desc>
        <ul class="desc">
          <li>近30天累计单量10单</li>
          <li>大于或等于10单的收费</li>
          <li>价格由系统自动生成</li>
          <li>累计达50票后以第三阶段收费</li>
        </ul>
      </template>
    </quote-service-slot>
    <quote-service-slot :bgcolor="bgColor[2]">
      <template #title>第三阶梯</template>
      <template #price>10</template>
      <template #priceTextDesc>近30天总单量超出50单</template>
      <template #desc>
        <ul class="desc">
          <li>近30天累计单量50票</li>
          <li>即超出49票后收费标准</li>
          <li>价格超低服务升级</li>
          <li>价格由系统自动生成</li>
        </ul>
      </template>
    </quote-service-slot>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import QuoteServiceSlot from './QuoteServiceSlot.vue';

export default {
  name: 'quote-service',
  components: { QuoteServiceSlot },
  setup() {
    /**
     * 数据
     */
    const datas = reactive({
      bgColor: ['#ACDD58', '#599EDB', '#EA4F5C'],
    });
    /**
     * 方法
     */
    const myMethods = reactive({

    });
    return {
      ...toRefs(datas),
      ...toRefs(myMethods),
    };
  },
};
</script>
<style lang="scss" scoped>
.price_box {
  display: flex;
  justify-content: space-between;

}

.desc {
  background-color: white;

  li {
    // padding-left: $base * 0.5;
    border-bottom: 1px solid $light;
    line-height: $base * 2.8;
    font-size: $base * 0.8;
    text-align: center;
  }
}
</style>
