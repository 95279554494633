<template>
  <div class="home">
    <div class="j_cardlist">
      <div class="title">
        <el-icon :key="18">
          <Menu />
        </el-icon>
        <span>订单总览</span>
      </div>
      <HomeCardList />
    </div>
    <div class="j_gridapply">
      <div class="title">
        <el-icon :size="20">
          <guide />
        </el-icon>
        <span>本系统适用对象</span>
      </div>
      <GridApply />
    </div>
    <div class="j_sysDesc">
      <div class="title">
        <el-icon :size="20">
          <help-filled />
        </el-icon>
        <span>软件使用概阅</span>
      </div>
      <HelpContent />
    </div>
    <div class="j_quote">
      <div class="title">
        <el-icon :size="20">
          <histogram />
        </el-icon>
        <span>服务价格</span>
      </div>
      <QuoteService />
    </div>
    <hr />
    <div class="footevue">
      <FooterVue />
    </div>
  </div>
</template>

<script>
import {
  Menu, HelpFilled, Guide, Histogram,
} from '@element-plus/icons';
import HomeCardList from '../components/home/HomeCardList.vue';
import HelpContent from '../components/home/HelpContent.vue';
import QuoteService from '../components/home/QuoteService.vue';
import GridApply from '../components/home/GridApply.vue';
import FooterVue from '../components/home/FooterVue.vue';

export default {
  name: 'Home',
  components: {
    HomeCardList,
    Menu,
    HelpFilled,
    Guide,
    FooterVue,
    Histogram,
    HelpContent,
    QuoteService,
    GridApply,
  },
};
</script>
<style lang="scss" scoped>
  .home {
    div {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .title {
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
      font-weight: bold;
      .el-icon {
        margin-right: 5px;
      }
    }
  }
  .j_sysDesc {
    height: 350px;
  }
  .j_quote {
    margin-bottom: 50px;
  }
  .footevue {
    text-align: center;
  }
</style>
