<template>
  <div class="subslot">
    <div
      class="smallDiv"
      :style="'background-image: linear-gradient(45deg, '+elColors[0]+' , '+elColors[1]+');'"
    ></div>
    <div class="lineDiv" :style="'--startColor:'+elColors[1]+';--endColor:'+elColors[2]"></div>
    <div
      class="bigDiv"
      :style="'background-image: linear-gradient(45deg, '+elColors[2]+' , '+elColors[3]+');'"
    ></div>
    <slot name="topDiv"></slot>
    <!-- <div class="topDiv">
      <div class="topDivLine" :style="'background:'+elColors[3]">
        <slot name="icon" class="img"></slot>
      </div>
      <div class="topSpan">
        <slot name="desc">文字</slot>
      </div>
    </div>-->
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue';

export default {
  props: ['elColors'],
  setup(props) {
    const datas = reactive({
      elColors: props.elColors,
    });
    // console.log(datas.elColors[0]);
    return {
      ...toRefs(datas),
    };
  },
};
</script>
<style lang="scss" scoped>
.subslot {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	.smallDiv {
		width: $divBaseSpace * 1.5;
		height: $divBaseSpace * 1.5;
		border-radius: 20%;
		z-index: 3;
		transform: rotate(45deg);
		position: absolute;
		left: (-$divBaseSpace * 0.75 * 5)/2-$divBaseSpace/1.5;
	}
	.lineDiv {
		flex: 1;
		height: $divBaseSpace * 0.75;
		width: 100%;
		z-index: 2;
		&::before {
			content: '';
			width: 100%;
			height: $divBaseSpace * 0.75;
			background-image: linear-gradient(
				to right,
				var(--startColor),
				var(--endColor)
			);
			display: block;
			position: absolute;
			left: (-$divBaseSpace * 0.75 * 5)/2 + $divBaseSpace/1.6;
		}
		// background-color: yellow;
	}
	.bigDiv {
		width: $divBaseSpace * 0.75 * 5;
		height: $divBaseSpace * 0.75 * 5;
		// background-color: yellow;
		transform: rotate(45deg);
		border-radius: 20%;
		z-index: 3;
	}
	// .topDiv {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: $divBaseSpace * 3.9;
	// 	height: 50%;
	// 	display: flex;
	// 	justify-content: center;
	// 	z-index: 1;
	// 	// background-color: red;
	// 	.topDivLine {
	// 		width: $divBaseSpace * 3;
	// 		height: $divBaseSpace * 3;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		position: absolute;
	// 		top: 20%;
	// 		border-radius: 20%;
	// 	}
	// 	&::before {
	// 		content: '';
	// 		position: absolute;
	// 		left: $divBaseSpace*0.75*5/2;
	// 		top: 30%;
	// 		width: 2px;
	// 		height: 80%;
	// 		background-color: $hovefontcolor;
	// 		z-index: 0;
	// 	}
	// 	.topSpan {
	// 		text-align: center;
	// 		font-size: 12px;
	// 		line-height: 15px;
	// 		text-shadow: 0 0 3px $hovefontcolor;
	// 	}
	// }
}
</style>
